// eslint-disable-next-line no-shadow
export enum TestExecutionStatusPhases {
  Pending = "pending",
  InProgress = "in_progress",
  Failed = "failed",
  Succeeded = "succeeded",
  Canceled = "canceled",
}

export interface Message {
  startedAt?: string;
  finishedAt?: string;
  headers: Record<string, string>;
  body: any;
}

export interface Req {
  proto: string;
  method: string;
  uri: string;
  host: string;
  query: Record<string, string>;
  message?: Message;
}

export interface Res {
  error?: string;
  proto: string;
  statusCode?: number;
  message?: Message;
}

export interface DiffOpClassification {
  noise: number;
}

export interface DiffOp {
  op: "add" | "remove" | "replace";
  path: string;
  value?: any;
  oldValue?: any;
  classification: DiffOpClassification;
}

export interface RequestDiff {
  baseline?: Req;
  target?: Req;
  diffOps: DiffOp[];
}

export interface ResponseDiff {
  baseline?: Res;
  target?: Res;
  diffOps: DiffOp[];
}

export interface PairdDiff {
  request: RequestDiff;
  response: ResponseDiff;
}

export interface CapturedBy {
  kind: "Job" | "Deployment" | "Rollout";
  namespace: string;
  name: string;
  direction: "Inbound" | "Outbound";
}

export interface CheckResultItem {
  name: string;
  errors?: {
    sourcePosition: {
      Line: number;
      Col: number;
    };
    message?: string;
  }[];
}

export interface CheckResults {
  sandbox?: CheckResultItem[];
  baseline?: CheckResultItem[];
}

export interface TrafficDiffSummary {
  captures: number;
  additions: number;
  removals: number;
  replacements: number;
  maxRelevance: number;
}

export interface TrafficDiff extends TrafficDiffSummary {
  capturePoints: CapturedBy[];
  red?: TrafficDiffSummary;
  yellow?: TrafficDiffSummary;
  green?: TrafficDiffSummary;
}

export type CreateTestExecution = {
  executionContext: {
    autoDiff?: {
      enabled: boolean;
    };
    cluster?: string;
    routing?: {
      routegroup: string;
      sandbox: string;
    };
  };
};

export interface ExecutionTemplate {
  cluster: string;
  autoDiff?: {
    enabled: boolean;
  };
}

export interface ExecutionContext extends ExecutionTemplate {
  routing?: {
    sandbox?: string;
    routegroup?: string;
  };
}

export interface Trigger {
  id: string;
  sandboxOf: {
    kind: string;
    namespace: string;
    name: string;
  };
  executionTemplate: ExecutionTemplate;
}

export type TestExecutionPhase =
  | TestExecutionStatusPhases.Pending
  | TestExecutionStatusPhases.InProgress
  | TestExecutionStatusPhases.Failed
  | TestExecutionStatusPhases.Succeeded
  | TestExecutionStatusPhases.Canceled;

export type TestExecutionResults = {
  trafficDiff?: TrafficDiff;
  checks?: CheckResults;
};

export interface TestExecution {
  id: string; // Only for UI purpose as needed by the list component.
  name: string;
  spec: {
    test: string;
    executionContext: ExecutionContext;
  };
  status: {
    testDeletedAt?: string;
    phase: TestExecutionPhase;
    startedAt?: string;
    finishedAt?: string;
    finalState?: {
      canceled?: {
        canceledBy: "timeout" | "apiRequest" | "internal";
        message: string;
      };
      failed?: {
        message: string;
      };
    };
    targetJob: string;
    baselineJob: string;
    triggeredBy?: TestExecutionResults;
  };
  results?: TestExecutionResults;
  createdAt: string;
}

export interface TestExecutionStatus {
  name: string;
  testName: string;
  phase: TestExecutionPhase;
  results?: TestExecutionResults;
}

export interface QueryResult {
  execution: TestExecution;
  cursor: string;
}
