import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import {
  FILTER_TEST_EXECUTIONS_API_NAME,
  useGetSandboxTestExecutions,
} from "../../../../api/TestExecutionsApi";
import style from "./Tests.module.css";
import { groupByTestCompletion } from "./utils";
import EmptyExecutions from "./EmptyExecutions/EmptyExecutions";
import SummaryExecutions from "../../../Testing/TestExecutionDetail/common/SummaryExecutions/SummaryExecutions";
import {
  NoCompletedTestDetails,
  TestExecutionCheck,
  TestExecutionDiff,
} from "../../../Testing/TestExecutionDetail/TestExecutionWrapper";

interface Props {
  sandbox: SandboxV2;
}

const Tests: React.FC<Props> = ({ sandbox }) => {
  const queryClient = useQueryClient();
  const { hash } = useLocation();

  useEffect(() => {
    queryClient.invalidateQueries(FILTER_TEST_EXECUTIONS_API_NAME);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState<
    "diffs" | "checks" | "tests" | "none"
  >("none");

  const { isSuccess, isLoading, data } = useGetSandboxTestExecutions(
    sandbox.name,
    {
      enabled: !!sandbox?.name,
    }
  );

  useEffect(() => {
    if (hash) {
      const parsedHash = hash.substring(1);
      if (["diffs", "checks", "tests"].includes(parsedHash)) {
        setHighlightedSection(parsedHash as typeof highlightedSection);
      }
    }
  }, [hash]);

  useEffect(() => {
    if (!data) return;

    if (highlightedSection === "none") return;

    const element = containerRef.current?.querySelector(
      `[data-section="${highlightedSection}"]`
    );

    if (!element) return;

    const timeoutID = setTimeout(() => setHighlightedSection("none"), 3000);
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });

    return () => {
      clearTimeout(timeoutID);
    };
  }, [data]);

  if (!isSuccess || isLoading || !data) {
    return null;
  }
  if (data.length === 0) {
    return <EmptyExecutions />;
  }

  const groupedExecutionByCompletion = groupByTestCompletion(data);

  return (
    <div ref={containerRef}>
      <SummaryExecutions executions={data} />

      <div
        className={classNames(style.section, {
          [style.section_highlighted]: highlightedSection === "diffs",
        })}
        data-section="diffs"
      >
        <span className="mb-5">Diff</span>
        {groupedExecutionByCompletion.completed.map((execution) => (
          <TestExecutionDiff
            testName={execution.testName}
            key={`diff-${execution.testName}`}
            textExecutionName={execution.name}
          />
        ))}
      </div>

      <div
        className={classNames(style.section, {
          [style.section_highlighted]: highlightedSection === "checks",
        })}
        data-section="checks"
      >
        <span>Checks</span>
        {groupedExecutionByCompletion.completed.map((execution) => (
          <TestExecutionCheck
            testName={execution.testName}
            key={`diff-${execution.testName}`}
            textExecutionName={execution.name}
          />
        ))}
      </div>

      {groupedExecutionByCompletion.others.length > 0 && (
        <div
          className={classNames(style.other_section, {
            [style.section_highlighted]: highlightedSection === "tests",
          })}
          data-section="tests"
        >
          <span>Tests (Pending/Failed)</span>
          {groupedExecutionByCompletion.others.map((execution) => (
            <NoCompletedTestDetails
              execution={execution}
              key={execution.name}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Tests;
